export const ROUTES = {
  BUSINESSES: '/businesses',
  BUSINESSES_EDIT: '/businesses/:id',
  REVIEWS: '/reviews',
  REVIEWS_EDIT: '/reviews/:id',
  CUSTOMERS: '/customers',
  TAG_PAGES: '/tag-pages',
  TAG_PAGES_EDIT: '/tag-pages/:id',
  FILTERED_PAGES: '/filtered-pages',
  FILTERED_PAGES_EDIT: '/filtered-pages/:id',
}
