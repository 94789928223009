'use client'

import { forwardRef, useEffect, useState } from 'react'
import { CheckCircle, Error, Warning } from '@mui/icons-material'
import {
  Box,
  BoxProps,
  styled,
  Typography,
  TypographyProps,
} from '@mui/material'

const variantColors = {
  success: {
    background: '#4CAF50',
    bgLight: '#81C784',
    color: '#fff',
    icon: <CheckCircle />,
  },
  error: {
    background: '#f44336',
    bgLight: '#E57373',
    color: '#fff',
    icon: <Error />,
  },
  warning: {
    background: '#ff9800',
    bgLight: '#FFB74D',
    color: '#fff',
    icon: <Warning />,
  },
  info: {
    background: '#6FAAF6',
    bgLight: '#93BFF8',
    color: '#fff',
    icon: <CheckCircle />,
  },
  default: {
    background: '#6FAAF6',
    bgLight: '#93BFF8',
    color: '#fff',
    icon: <CheckCircle />,
  },
}

interface SnackbarContentProps {
  message: string
  id: string | number
  variant?: 'default' | 'error' | 'success' | 'warning' | 'info'
  action?: React.ReactNode
}

const SnackbarContent = forwardRef<HTMLDivElement, SnackbarContentProps>(
  ({ message, id, variant = 'default', action }, ref) => {
    const [width, setWidth] = useState(100)

    useEffect(() => {
      const timer = setInterval(() => {
        setWidth((prev) => (prev <= 0 ? 0 : prev - 0.4))
      }, 20)

      return () => {
        clearInterval(timer)
      }
    }, [])

    return (
      <StyledContent
        key={id}
        ref={ref}
        sx={{
          background: variantColors[variant].bgLight,
        }}
      >
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            width: `${width}%`,
            transition: 'width 100ms linear',
            backgroundColor: variantColors[variant].background,
          }}
        />
        <StyledIconBox color={variantColors[variant].color}>
          {variantColors[variant].icon}
        </StyledIconBox>
        <StyledTypography color={variantColors[variant].color}>
          {message}
        </StyledTypography>
        {action && <StyledBox>{action}</StyledBox>}
      </StyledContent>
    )
  }
)

SnackbarContent.displayName = 'SnackbarContent'

export default SnackbarContent

const StyledContent = styled(Box)(() => ({
  position: 'relative',
  overflow: 'hidden',
  padding: '8px 12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  gap: '8px',
  maxWidth: '380px',
  minWidth: '280px',
}))

const StyledBox = styled(Box)(() => ({
  position: 'relative',
  zIndex: 1,
}))

const StyledTypography = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color',
})<TypographyProps & { color?: string }>(({ color }) => ({
  position: 'relative',
  zIndex: 1,
  color,
  fontSize: '12px',
  lineHeight: '1.2',
  fontWeight: 500,
  flex: 1,
  textAlign: 'left',
}))

const StyledIconBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'color',
})<BoxProps & { color?: string }>(({ color }) => ({
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  color,
  '& svg': {
    fontSize: '16px',
  },
}))
