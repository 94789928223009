'use client'

import React from 'react'
import {
  SnackbarContent as NotistackSnackbarContent,
  SnackbarProvider as NotistackSnackbarProvider,
} from 'notistack'

import CloseButton from './CloseButton'
import SnackbarContent from './SnackbarContent'
import styles from './styles.module.scss'

type SnackbarProps = {
  persist?: boolean
  id: string
  message: string
  [key: string]: any
}

export default function SnackbarProvider({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <NotistackSnackbarProvider
      maxSnack={3}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      Components={{
        success: React.forwardRef<HTMLDivElement, SnackbarProps>(
          function SuccessSnackbar({ id, message }, ref) {
            return (
              <NotistackSnackbarContent ref={ref}>
                <SnackbarContent
                  id={id}
                  message={message}
                  variant="success"
                  action={<CloseButton snackbarId={id} />}
                />
              </NotistackSnackbarContent>
            )
          }
        ),
        error: React.forwardRef<HTMLDivElement, SnackbarProps>(
          function ErrorSnackbar({ id, message }, ref) {
            return (
              <NotistackSnackbarContent ref={ref}>
                <SnackbarContent
                  id={id}
                  message={message}
                  variant="error"
                  action={<CloseButton snackbarId={id} />}
                />
              </NotistackSnackbarContent>
            )
          }
        ),
        warning: React.forwardRef<HTMLDivElement, SnackbarProps>(
          function WarningSnackbar({ id, message }, ref) {
            return (
              <NotistackSnackbarContent ref={ref}>
                <SnackbarContent
                  id={id}
                  message={message}
                  variant="warning"
                  action={<CloseButton snackbarId={id} />}
                />
              </NotistackSnackbarContent>
            )
          }
        ),
        info: React.forwardRef<HTMLDivElement, SnackbarProps>(
          function InfoSnackbar({ id, message }, ref) {
            return (
              <NotistackSnackbarContent ref={ref}>
                <SnackbarContent
                  id={id}
                  message={message}
                  variant="info"
                  action={<CloseButton snackbarId={id} />}
                />
              </NotistackSnackbarContent>
            )
          }
        ),
        default: React.forwardRef<HTMLDivElement, SnackbarProps>(
          function DefaultSnackbar({ id, message }, ref) {
            return (
              <NotistackSnackbarContent ref={ref}>
                <SnackbarContent
                  id={id}
                  message={message}
                  variant="default"
                  action={<CloseButton snackbarId={id} />}
                />
              </NotistackSnackbarContent>
            )
          }
        ),
      }}
      className={styles.snackbarProvider}
    >
      {children}
    </NotistackSnackbarProvider>
  )
}
