'use client'

import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { useSnackbar } from 'notistack'

const CloseButton = ({ snackbarId }: { snackbarId: number | string }) => {
  const { closeSnackbar } = useSnackbar()
  return (
    <IconButton
      size="small"
      aria-label="close"
      onClick={() => closeSnackbar(snackbarId)}
      sx={{
        color: 'white',
        position: 'relative',
        zIndex: 1,
        width: '16px',
        height: '16px',
        '& svg': {
          width: '14px',
          height: '14px',
        },
      }}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )
}

export default CloseButton
